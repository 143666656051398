import { useContext } from 'react';
import { LayoutGroupContext } from '../component/LayoutGroupContext';

/**
 * Pulls props provided by a LayoutGroup from a
 * [feature switch](https://github.homedepot.com/thd-olt-component-react/layout-manager/wiki/Feature-Switches).
 * This requires the `LayoutManager` to set the `useContextForProps` attribute.
 *
 * @example
 * <LayoutManager useContextForProps>
 *   <LayoutGroup name="buybox">
 *     <Buybox />
 *   </LayoutGroup>
 * </LayoutManager>
 * // Buybox.js
 * function Buybox({ props }) {
 *   const { bodfsMinimumThreshold } = useLayoutGroupProps({ props });
 * }
 * @param props
 * @returns {{}|{[p: string]: *}|*}
 */
export const useLayoutGroupProps = ({ props }) => {
  const ctx = useContext(LayoutGroupContext);
  if (!ctx) return props;
  if (!ctx.useContextForProps) return props;

  const obj = { ...(ctx.props || {}), ...(props || {}) };

  return Object.keys(obj || {})
    .reduce((acc, cur) => {
      return {
        ...acc,
        [cur]: ctx.props && typeof ctx.props[cur] !== 'undefined'
          ? ctx.props[cur]
          : props[cur]
      };
    }, {});
};
