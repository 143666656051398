import React, { useEffect } from 'react';
import { string } from 'prop-types';
import { useHelmet, MetaTag } from '@thd-nucleus/thd-helmet';

const contentMetaHandler = ({ props }) => {
  const { contentName } = props;
  const tags = [];
  if (contentName.toLowerCase() === 'curbside_pickup') {
    tags.push(new MetaTag({
      // note: Look into this coming from API as standard
      property: 'og:image',
      // eslint-disable-next-line max-len
      content: 'https://contentgrid.thdstatic.com/hdus/en_US/DTCCOMNEW/fetch/FetchRules/Partial/THD-StoreFront-1600x1600.jpg',
      id: 'OriginalImageFaceBook'
    }));
    tags.push(new MetaTag({
      name: 'apple-itunes-app',
      content: 'app-id=342527639, app-clip-bundle-id=com.thehomedepot.homedepot.appclip',
      id: 'appclip'
    }));
  }
  return tags;
};
const ContentMetadata = ({ contentName }) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('content-metadata.ready');
  }, []);
  useHelmet('curbsideMeta', { props: { contentName } }, contentMetaHandler, []);
  return null;
};

ContentMetadata.displayName = 'ContentMetadata';

ContentMetadata.propTypes = {
  contentName: string.isRequired
};

export { ContentMetadata };