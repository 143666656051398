import React, { useContext, useEffect, useState } from 'react';
import { ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';
import PropTypes from 'prop-types';
import { useLayoutGroupProps, withLayoutGroup } from '@thd-olt-component-react/layout-manager';
import { useDataModel } from '@thd-nucleus/data-sources';
import { RecsContext } from '../RecsContext';
import { dataModel } from './dataModel';
import { RecommendationsBanner } from './RecommendationsBanner';

const RecommendationsPlaceholderComponent = (props) => {
  const context = useContext(RecsContext);
  const expContext = useContext(ExperienceContext);
  const {
    scheme,
    schema,
    itemId,
    hideRecommendation,
    defer,
    shouldDisplayCard
  } = props;
  const path = expContext?.path;
  const isServer = expContext?.isServer;
  const { channel } = expContext;
  const { updateResx, addSchema, getResx } = context;
  const [showRecsHeader, setShowRecsHeader] = useState(false);
  const { useNewImpressionLogic, useBannerPlaceholder } = useLayoutGroupProps({ props });

  useEffect(() => {
    if (addSchema) {
      addSchema(scheme || schema, defer);
    }
  }, [scheme, schema]);

  useEffect(() => {
    if (updateResx) {
      if (useNewImpressionLogic) {
        updateResx({ useNewImpressionLogic: true });
      }
    }
  }, []);

  useEffect(() => {
    const validReferrer = /google\.com|bing\.com|yahoo\.com/.test(window.document.referrer);
    const externalRedirect = /cm_mmc/.test(path);
    const shouldShow = externalRedirect || (!isServer && validReferrer);
    setShowRecsHeader(shouldShow);
  }, [isServer, path]);

  const schemen = scheme || schema;
  const storeId = useStoreId();

  const { loading, error, data } = useDataModel('clientOnlyProduct', {
    variables: {
      itemId,
      storeId,
    },
    skip: !itemId || ((schemen !== 'visuallysimilar_1_0' || !itemId || channel === 'mobile') && storeId === '8119'),
    ssr: false
  });

  useEffect(() => {
    const visSimCard = shouldDisplayCard && schemen === 'visuallysimilar_1_0';
    if (data && !visSimCard && updateResx) {
      const primary = data.product?.media?.images?.find((img) => img.subType === 'PRIMARY');
      const index = primary?.sizes?.indexOf('400');
      if (index > -1) {
        const productImageURL = primary.url.replace('<SIZE>', '400');
        if (productImageURL) {
          updateResx({ visSim: { productImageURL } });
        }
      }
    } if (visSimCard && updateResx) {
      updateResx({ card: true });
    }
  }, [data]);

  if (hideRecommendation && (schemen === 'pipsem' || schemen === 'pip_sem_mobile'
     || schemen === 'PIPHorizontal2_rr') && !showRecsHeader) {
    return null;
  }

  if (useBannerPlaceholder && schemen.indexOf('sponsored__banner') > -1) {
    let windowFunc;
    if (typeof window !== 'undefined') {
      windowFunc = window.__GET_RESX__;
    }

    const getResxFunc = getResx || windowFunc || function () { return {}; };
    const resx = getResxFunc();

    if (resx.fetchBanner) {
      return <RecommendationsBanner schemen={schemen} />;
    }
  }

  return (
    <div id={schemen} />
  );
};

RecommendationsPlaceholderComponent.displayName = 'RecommendationsPlaceholder';

const propTypes = {
  defer: PropTypes.bool,
  itemId: PropTypes.string,
  scheme: PropTypes.string.isRequired,
  schema: PropTypes.string,
  hideRecommendation: PropTypes.bool,
  shouldDisplayCard: PropTypes.bool
};
const defaultProps = {
  defer: false,
  itemId: null,
  schema: null,
  hideRecommendation: false,
  shouldDisplayCard: false
};

RecommendationsPlaceholderComponent.propTypes = propTypes;
RecommendationsPlaceholderComponent.defaultProps = defaultProps;
RecommendationsPlaceholderComponent.dataModel = dataModel;

const RecommendationsPlaceholder = withLayoutGroup({ name: 'default-recs' }, RecommendationsPlaceholderComponent);
RecommendationsPlaceholder.propTypes = propTypes;
RecommendationsPlaceholder.defaultProps = defaultProps;
RecommendationsPlaceholder.dataModel = dataModel;
export { RecommendationsPlaceholder };
