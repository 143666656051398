import {
  params, string, shape, arrayOf, alias, bool
} from '@thd-nucleus/data-sources';

export const dataModel = {
  clientOnlyProduct: alias('product').params({ itemId: string().isRequired(), }).shape({
    itemId: string(),
    dataSources: string(),
    media: shape({
      images: arrayOf(shape({
        url: string(),
        sizes: arrayOf(string()),
        type: string(),
        subType: string()
      }))
    })
  })
};
