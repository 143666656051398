import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { string } from 'prop-types';

export const RecommendationsBanner = ({ schemen }) => {

  const { channel, isConsumerApp } = useContext(ExperienceContext);

  let defaultBannerMap = [
    {
      imgDesktop: 'https://contentgrid.thdstatic.com/hdus/en_US/DTCCOMNEW/fetch/bopis-desktop.jpg',
      imgMobile: 'https://contentgrid.thdstatic.com/hdus/en_US/DTCCOMNEW/fetch/bopis-mobile.jpg',
      clickthruURL: 'https://www.homedepot.com/c/pick_up_in_store'
    },
    {
      imgDesktop: 'https://contentgrid.thdstatic.com/hdus/en_US/DTCCOMNEW/fetch/delivery-desktop.jpg',
      imgMobile: 'https://contentgrid.thdstatic.com/hdus/en_US/DTCCOMNEW/fetch/delivery-mobile.jpg',
      clickthruURL: 'https://www.homedepot.com/c/About_Your_Online_Order'
    },
  ];
  if (!isConsumerApp) {
    defaultBannerMap = [
      ...defaultBannerMap,
      {
        imgDesktop: 'https://contentgrid.thdstatic.com/hdus/en_US/DTCCOMNEW/fetch/app-desktop.jpg',
        imgMobile: 'https://contentgrid.thdstatic.com/hdus/en_US/DTCCOMNEW/fetch/app-mobile.jpg',
        clickthruURL: 'https://www.homedepot.com/c/SF_Mobile_Shopping'
      }
    ];
  }

  const defaultBanner = defaultBannerMap[Math.floor(Math.random() * defaultBannerMap.length)];

  defaultBanner.url = defaultBanner.imgDesktop;
  if (channel === 'mobile') {
    defaultBanner.url = defaultBanner.imgMobile;
  }

  let imgSrc = defaultBanner.url;
  let href = defaultBanner.clickthruURL;
  if (typeof window !== 'undefined') {
    try {
      const aTag = window.document.querySelector(`#${schemen} a`);

      imgSrc = aTag.dataset.img;
      href = aTag.href;
    } catch (error) { /* */ }
  }

  const paddingTop = channel === 'mobile' ? '37.7%' : '10.6%';

  const cssObj = {
    width: '100%',
    display: 'block',
    'box-sizing': 'border-box',
    background: `url("${imgSrc}") no-repeat`,
    'background-size': '100%',
    'padding-top': paddingTop
  };

  return (
    <div
      data-usebannerplaceholder="true"
      id={schemen}
    >
      <div id="default_banner_carousel">
        { /* eslint-disable-next-line */ }
        <a href={href} style={cssObj} data-img={imgSrc} />
      </div>
    </div>
  );
};

RecommendationsBanner.propTypes = {
  schemen: string.isRequired
};
