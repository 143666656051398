import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  A11y,
  Autoplay,
  Mousewheel,
  Pagination,
  Navigation,
  Scrollbar
} from 'swiper';
import { Typography } from '@one-thd/sui-atomic-components';
import { Paginator } from './Paginator';

const SCREEN_SM = 640;
const SCREEN_MD = 768;
const SCREEN_LG = 1024;
const SCREEN_XL = 1280;

/**
 * `Carousel` allows multiple pieces of content to occupy a single space on a screen.
 *
 * It usually contains `Card`s as its children, but can also contain other components like native
 * `img` or `picture` elements.
 *
 * `Carousel` needs to be imported from the `@one-thd/sui-carousel` package:
 *
 * Related components: [Card](#card)
 *
 * ```jsx
 * import { Carousel } from '@one-thd/sui-carousel';
 * ```
 */
const Carousel = (props) => {
  const {
    action,
    autoplay = false,
    breakpoints,
    children,
    hidePaginationOnOneOfOne = false,
    disableShadow,
    hideControls = false,
    slidesPerGroup,
    slidesPerView,
    spaceBetween,
    SwiperProps,
    loop,
    title: titleProp,
    disableMargin,
    type
  } = props;

  const isHero = type === 'hero';

  const _breakpoints = isHero ? {} : {
    [SCREEN_SM]: {
      slidesPerView: breakpoints?.sm ? breakpoints.sm.slidesPerView : 0,
      slidesPerGroup: breakpoints?.sm?.slidesPerGroup,
      spaceBetween: breakpoints?.sm?.spaceBetween || spaceBetween
    },
    [SCREEN_MD]: {
      slidesPerView: breakpoints?.md ? breakpoints.md.slidesPerView : 0,
      slidesPerGroup: breakpoints?.md?.slidesPerGroup,
      spaceBetween: breakpoints?.md?.spaceBetween || spaceBetween
    },
    [SCREEN_LG]: {
      slidesPerView: breakpoints?.lg ? breakpoints.lg.slidesPerView : 0,
      slidesPerGroup: breakpoints?.lg?.slidesPerGroup,
      spaceBetween: breakpoints?.lg?.spaceBetween || spaceBetween
    },
    [SCREEN_XL]: {
      slidesPerView: breakpoints?.xl ? breakpoints.xl.slidesPerView : 0,
      slidesPerGroup: breakpoints?.xl?.slidesPerGroup,
      spaceBetween: breakpoints?.xl?.spaceBetween || spaceBetween
    }
  };

  const onSwiperSlideKeyUp = ({ key, currentTarget }, index) => {
    const cardAnchor = currentTarget?.querySelector('a[tabindex="0"],button');
    if (key === 'ArrowRight' && index !== children.length - 1) {
      currentTarget?.nextSibling?.querySelector('a[tabindex="0"],button')?.focus();
    } else if (key === 'ArrowLeft' && index !== 0) {
      currentTarget?.previousSibling?.querySelector('a[tabindex="0"],button')?.focus();
    } else if ((key === ' ' || key === 'Enter') && cardAnchor) {
      cardAnchor.click();
    }
  };

  let title = titleProp;
  if (title != null && title?.type?.displayName !== 'Typography') {
    title = <Typography variant="h2">{title}</Typography>;
  }

  const header = (
    <>
      {title != null || action != null ? (
        <div className="sui-flex sui-justify-between">
          {title}
          {action ? <div className="sui-m-2">{action}</div> : null}
        </div>
      ) : null}
    </>
  );

  const scrollbarSettings = !loop && {
    draggable: true,
    horizontalClass: 'sui-swiper-scrollbar',
    dragClass: ['-sui-mt-1', 'sui-bg-inverse', 'sui-h-2', 'sui-cursor-pointer']
  };

  // Keep scrollbar always visible when dragging it
  const onScrollbarDragStart = (swiper) => {
    const scrollBar = swiper.scrollbar.el;
    scrollBar.classList.add('sm:sui-opacity-100');
  };
  const onScrollbarDragEnd = (swiper) => {
    const scrollBar = swiper.scrollbar.el;
    scrollBar.classList.remove('sm:sui-opacity-100');
  };

  const classes = classNames('sui-overflow-hidden !sui-flex sui-flex-wrap sui-justify-end sui-group [&>.swiper-wrapper]:sui-flex [&>.swiper-wrapper]:sui-mt-1 [&>.swiper-wrapper]:sui-w-full', {
    'sui-mb-8': !disableMargin,
  });

  return (
    <div>
      {header}
      <Swiper
        modules={[A11y, Autoplay, Mousewheel, Scrollbar, Pagination, Navigation]}
        autoplay={autoplay ? {
          pauseOnMouseEnter: true,
          delay: 5000
        } : false}
        breakpoints={_breakpoints}
        className={classes}
        spaceBetween={spaceBetween}
        slidesPerView={isHero ? 1 : slidesPerView}
        slidesPerGroup={isHero ? 1 : slidesPerGroup}
        scrollbar={scrollbarSettings}
        mousewheel={{
          forceToAxis: true
        }}
        a11y={{ notificationClass: ['swiper-notification', 'sui-absolute', 'sui-opacity-0', 'sui-top-0', 'sui-left-0'] }}
        onScrollbarDragStart={onScrollbarDragStart}
        onScrollbarDragEnd={onScrollbarDragEnd}
        loop={loop}
        {...SwiperProps}
      >
        {children.map((slide, index) => {
          return (
            <SwiperSlide
              key={`slide-${index}`}
              className={classNames('sui-shrink-0 [&>*]:sui-h-full sui-touch-pan-y', {
                'hover:sui-shadow-lg sui-transition-shadow': !disableShadow
              })}
              onKeyUp={(event) => onSwiperSlideKeyUp(event, index)}
            >
              {slide}
            </SwiperSlide>
          );
        })}
        {!hideControls && (
          <div slot="container-end" className="sui-flex sui-justify-center sui-items-center">
            <Paginator hidePaginationOnOneOfOne={hidePaginationOnOneOfOne} loop={loop} />
          </div>
        )}
      </Swiper>
    </div>
  );
};

Carousel.displayName = 'Carousel';

Carousel.propTypes = {
  /**
   * The action that goes in the upper right corner of carousel
   */
  action: PropTypes.node,
  /**
   * When true, carousel will autoplay
   */
  autoplay: PropTypes.bool,
  /**
   * Number of slides to display based on breakpoint
   */
  breakpoints: PropTypes.shape({
    sm: PropTypes.shape({
      slidesPerView: PropTypes.number,
      slidesPerGroup: PropTypes.number,
      spaceBetween: PropTypes.number
    }),
    md: PropTypes.shape({
      slidesPerView: PropTypes.number,
      slidesPerGroup: PropTypes.number,
      spaceBetween: PropTypes.number
    }),
    lg: PropTypes.shape({
      slidesPerView: PropTypes.number,
      slidesPerGroup: PropTypes.number,
      spaceBetween: PropTypes.number
    }),
    xl: PropTypes.shape({
      slidesPerView: PropTypes.number,
      slidesPerGroup: PropTypes.number,
      spaceBetween: PropTypes.number
    })
  }),
  /**
   * Disables the slide box-shadow
   */
  disableShadow: PropTypes.bool,
  /**
   * Disable the default margin of 32px (mb-8) under the Carousel.
   * When set to true, the Carousel will have no margin below it.
  */
  disableMargin: PropTypes.bool,
  /**
   * If `true` will hide the carousel controls. (Note that hiding the controls will impact accesibility, especially when
   * the carousel has more than one slide. This prop won't prevent the scrollbar from showing up in these occasions.)
   * @ignore
  */
  hideControls: PropTypes.bool,
  /**
   * Hide pagination controls when set is placed all in one view
   */
  hidePaginationOnOneOfOne: PropTypes.bool,
  /**
   * When true, carousel will not disable the previous and next paginator buttons.
   * If Carousel is on the first slide and the previous button is clicked, it will paginate to the last group.
   * If Carousel is on the last slide and the next button is clicked, it will paginate to the first group.
   * Additionally this will hide the scrollbar as there is a
   * [known bug](https://github.com/nolimits4web/swiper/issues/2315#issuecomment-343162574) on the internal Swiper
   * library used.
   */
  loop: PropTypes.bool,
  /**
   * The carousel slides
   */
  children: PropTypes.node,
  /**
   * Number of slides to transition when clicking next/prev
   */
  slidesPerGroup: PropTypes.number,
  /**
   * Number of slides to display at once
   */
  slidesPerView: PropTypes.number,
  /**
   * The space between slides
   */
  spaceBetween: PropTypes.number,
  /**
   * Props applied to the underlying Swiper component
   */
  SwiperProps: PropTypes.object,
  /**
   * Title of carousel in upper left corner
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Two display types of carousel
   */
  type: PropTypes.oneOf(['hero', 'content'])
};

Carousel.defaultProps = {
  breakpoints: {
    sm: {
      slidesPerView: 3,
      slidesPerGroup: 3
    },
    md: {
      slidesPerView: 4,
      slidesPerGroup: 4
    },
    lg: {
      slidesPerView: 5,
      slidesPerGroup: 5
    },
    xl: {
      slidesPerView: 6,
      slidesPerGroup: 6
    }
  },
  children: [],
  disableShadow: false,
  disableMargin: false,
  hidePaginationOnOneOfOne: false,
  slidesPerGroup: 1,
  spaceBetween: 16,
  slidesPerView: 2,
  type: 'content'
};

export { Carousel };
