import { HTTP_CODE, ERROR_HANDLING_EXPERIENCE } from '../../utils/redirector-utils';

export const isNotFound = (data) => {
  if (data && !data.content) {
    return {
      shouldRedirect: false,
      isErrorStatus: true,
      httpCode: HTTP_CODE.NOT_FOUND,
      description: 'Page not found',
      errorHandlingExperience: ERROR_HANDLING_EXPERIENCE.GENERIC
    };
  }

  return null;
};