/* eslint react/no-find-dom-node: 0 */
/* globals certona */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { RecsContext } from './RecsContext';
import './recommendations.style.scss';

const dynamicRecSchemeList = [
  'categorylevel2horizontal1_rr',
  'visuallysimilar_1_0',
  'PLP_Browse',
  'PLP_Browse_In_Stock',
  'PLP_Search_In_Stock',
  'PLP_Browse_Mobile',
  'pip_alternatives',
  'pip_mobile',
  'pip_sem_mobile',
  'pipsem',
  'pipinstock',
  'pip_instock_mobile',
  'searchViewed',
  'articles_rec',
  'pnf'
];

class RecsContextProvider extends Component {

  state = {
    resx: {},
    ready: this.props.ready//eslint-disable-line
  };

  dynamicSchemeList = new Set();

  recommendationSchemeList = new Set();

  deferredSchemeList = new Set();

  deferredDynamicSchemeList = new Set();

  constructor(props) {
    super(props);
    this.startTime = new Date();
  }

  async componentDidMount() {
    await this.loadDependencies();
    const { ready } = this.state;
    const { resx } = this.props;

    if (typeof ready !== 'boolean') {
      this.setState({ ready: true, lastUrl: window.location.pathname }, () => this.loadRecs()); // eslint-disable-line
    } else if (ready) {
      this.loadRecs();
    }
    this.setupDeferredRecs();
  }

  componentDidUpdate(prevProps) {
    const { ready: readyState, lastUrl } = this.state;
    const { ready: readyProp, resx } = this.props;
    const readyUpdated = typeof readyProp !== 'undefined' && readyProp !== readyState;

    let productsUpdated = false;
    try {
      if (lastUrl && lastUrl !== window.location.pathname) {
        for (let i = 0; i < prevProps.resx.products.length; i += 1) {
          if (prevProps.resx.products[i] !== resx.products[i]) {
            productsUpdated = true;
            break;
          }
        }
      }

    } catch (error) { /* */ }

    if (readyUpdated || productsUpdated) {
      this.setState({ ready: true, lastUrl: window.location.pathname }, () => this.loadRecs()); // eslint-disable-line
    }
  }

  componentWillUnmount() {
    this.dynamicSchemeList.clear();
    this.recommendationSchemeList.clear();
    this.deferredSchemeList.clear();
    this.deferredDynamicSchemeList.clear();
  }

  setupDeferredRecs = () => {
    const hasDeferred = (this.deferredSchemeList.size > 0 || this.deferredDynamicSchemeList.size > 0);
    if (window && hasDeferred) {
      window.addEventListener('scroll', this.delayExec);
    }
  }

  delayExec = () => {
    window.removeEventListener('scroll', this.delayExec);
    setTimeout(this.executeDeferredRecs, 250);
  }

  executeDeferredRecs = () => {
    window.resx = this.getResx({ deferred: true });
    this.certona.refresh(window.resx);
  }

  getResx = (schemaOptions) => {
    const { deferred = false } = schemaOptions || {};
    const resx = {
      ...this.props.resx,//eslint-disable-line
      ...this.state.resx//eslint-disable-line
    };
    resx.dynamicRecsSchema = deferred ? Array.from(this.deferredDynamicSchemeList) : Array.from(this.dynamicSchemeList);
    resx.certonaSchema = deferred ? Array.from(this.deferredSchemeList) : Array.from(this.recommendationSchemeList);

    return resx;
  };

  loadDependencies = async () => {

    if (this.certona) {
      return Promise.resolve();
    }

    if (typeof window !== 'undefined') {
      const { channel } = this.context;

      window.THD_GLOBAL = window.THD_GLOBAL || {};
      window.THD_GLOBAL.secureHostName = window.THD_GLOBAL.secureHostName || 'www.homedepot.com';
      window.THD_GLOBAL.nonSecureHostName = window.THD_GLOBAL.nonSecureHostName || 'www.homedepot.com';
      window.THD_GLOBAL.host = window.THD_GLOBAL.host || '//www.homedepot.com';
      window.THD_GLOBAL.channel = window.THD_GLOBAL.channel || channel || 'mobile';
    }

    const certonaPromise = import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "thd-recommendations-all" */
      'thd-recommendations-all/dist/all.min'
    );
    const [certona] = await Promise.all([certonaPromise]);
    this.certona = certona;

    return Promise.resolve();
  };

  loadRecs = async () => {

    await this.loadDependencies();

    const { ready } = this.state;

    if (!ready) {
      return;
    }

    window.resx = this.getResx();
    this.certona.refresh(window.resx);
    if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined' && this.startTime) {
      const endTime = new Date().getTime();
      const startTime = this.startTime.getTime();
      (window.resx.certonaSchema || []).forEach((schema) => {
        LIFE_CYCLE_EVENT_BUS.trigger('performance.component-load', {
          componentName: `Recommendations - ${schema}`,
          startTime,
          endTime,
        });
      });
      this.startTime = null;
    }

  };

  addSchema = (schema, defer) => {
    const isDynamicRec = (dynamicRecSchemeList.indexOf(schema) !== -1);
    let listToAddTo = {};
    if (isDynamicRec) {
      listToAddTo = (!defer) ? this.dynamicSchemeList : this.deferredDynamicSchemeList;
    } else {
      listToAddTo = (!defer) ? this.recommendationSchemeList : this.deferredSchemeList;
    }
    listToAddTo.add(schema);
  };

  updateResx = (resx) => {
    this.setState((state) => ({
      ...state,
      resx
    }));
  };

  render() {
    const { children } = this.props;
    const context = {
      addSchema: this.addSchema,
      getResx: this.getResx,
      updateResx: this.updateResx
    };

    if (typeof window !== 'undefined') {
      window.__GET_RESX__ = this.getResx;
    }

    return (
      <RecsContext.Provider value={context}>
        {children}
      </RecsContext.Provider>
    );
  }
}

RecsContextProvider.contextType = ExperienceContext;
RecsContextProvider.displayName = 'RecsContextProvider';

RecsContextProvider.propTypes = {
  children: PropTypes.node,
  resx: PropTypes.shape({
    products: PropTypes.string,
    waitForClientResolved: PropTypes.bool,
    clientResolved: PropTypes.bool
  }),
  ready: PropTypes.bool
};

RecsContextProvider.defaultProps = {
  children: null,
  resx: { products: '', waitForClientResolved: false, clientResolved: false },
  ready: undefined
};

export default RecsContextProvider;
