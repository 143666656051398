import React from 'react';

const CONTEXT_KEY = Symbol.for('@thd-olt-component-react/recommendations/Context');
const globalSymbols = Object.getOwnPropertySymbols(global);
const hasContext = (globalSymbols.indexOf(CONTEXT_KEY) > -1);

const funcDefault = () => { };

/* istanbul ignore next */
if (!hasContext) {
  global[CONTEXT_KEY] = React.createContext({
    addSchema: funcDefault,
    getResx: funcDefault,
    updateResx: funcDefault
  });
}

export const RecsContext = global[CONTEXT_KEY];
