import React, { useContext, useEffect, useState } from 'react';
import {
  params,
  string,
  shape,
  useDataModel,
  extend,
  QueryContext,
  customType
} from '@thd-nucleus/data-sources';
import { useParams, useLocation } from '@thd-olt-component-react/router';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import {
  RecommendationsPlaceholder,
  RecommendationsProvider,
} from '@thd-olt-component-react/recommendations';
import { Heading } from '@thd-olt-component-react/core-ui';
import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';
import { EtchWidgets } from '@thd-olt-component-react/etch-widgets';
import { Metadata, ContentMetadata } from '@thd-olt-component-react/metadata';
import { Row, Col } from '@thd-olt-component-react/grid';
import { Hydrator, declareContexts } from '@thd-olt-component-react/hydrator';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { ContentRedirector } from '@thd-nucleus/app-render';
import { SponsoredNonEndemic } from '@thd-olt-component-react/sponsored-content';
import { EmtLinksLoad, EmtLinks, EmtLinksModel } from './dynamic-imports';

export const Content = (props) => {
  declareContexts([QueryContext, ExperienceContext]);
  const normalizeBreadcrumb = (label) => {
    return [
      {
        dimensionName: 'Content',
        label,
        url: `/c/${label}`,
      },
    ];
  };
  const ctx = useContext(ExperienceContext);
  const { name } = useParams();

  const { search: querystring, pathname } = useLocation();
  let contentName = '';
  if (querystring.includes('contentName')) {
    const urlParams = new URLSearchParams(querystring);
    contentName = urlParams.get('contentName');
  }
  const { data, loading, error } = useDataModel('content', {
    variables: {
      name,
      contentName,
      channel: ctx?.channel?.toUpperCase() || 'DESKTOP'
    }
  });

  // This is for the non-endemic sponsored banner campaign.
  const isNonEndemicBannerEnabled = useConfigService('fs-prop:isNonEndemicBannerEnabled') || false;
  const isDIYPage = pathname?.includes('diy_projects_and_ideas') || false;

  const { content } = data || {};
  const { breadcrumbs = [], metadata = {} } = content || {};
  const { heading } = metadata;

  const HYDRATION_STANDARD_SCROLL_BUFFER = 750;

  const [recommendationsState, setRecommendationState] = useState({
    ready: false,
    schema: {
      hp_sponsored: false,
      rv_homepage_rr: false,
      con1_home_rr: false,
    },
  });

  const [recxSchema, setrecxSchema] = useState({
    appId: 'HOMEDEPOT01',
    maxProducts: 16,
    products: null,
    showATC: true,
    criteo: {
      version: 'model2',
    },
    certonaSchema: [],
    udsRecsSchema: [],
    event: 'product',
    carouselOptions: {
      fullScrollSkip: 2,
    },
  });

  const makeStickyHeader = () => {
    const el = document.querySelector('.Header3');
    if (el) {
      const headerClass = ['Header3-sticky-desktop', 'Header3__header--slide'];
      el.classList.add(...headerClass);
    }
  };

  const initializeRecommendation = () => {
    const recState = {};
    const certonaList = document.getElementsByClassName('certona-content') || [];
    // eslint-disable-next-line
    for (const schema of certonaList) {
      const { id = '' } = schema || {};
      switch (id) {
      case 'hp_sponsored':
        recxSchema.certonaSchema.push('hp_sponsored');
        recState.hp_sponsored = true;
        break;
      case 'rv_homepage_rr':
        recxSchema.udsRecsSchema.push('rv_homepage_rr');
        recState.rv_homepage_rr = true;
        break;
      case 'con1_home_rr':
        recxSchema.udsRecsSchema.push('con1_home_rr');
        recState.con1_home_rr = true;
        break;
      default:
        break;
      }
    }

    if (certonaList.length) {
      setrecxSchema(recxSchema);
      setRecommendationState({
        ready: true,
        schema: recState,
      });
    }
  };

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('content.page-load', { heading });
    makeStickyHeader();
    initializeRecommendation();
  }, []);
  return (
    <Row name="content-wrapper">
      <ContentRedirector name={name} />
      <Row className="content-page grid isBound">
        <Col>
          <Metadata data={metadata}>
            <ContentMetadata contentName={name} />
          </Metadata>
          <Row className="breadcrumbs--scroll">
            <ErrorBoundary name="breadcrumbs">
              <Col flatten>
                <Breadcrumbs
                  staticCrumbs={breadcrumbs.length ? breadcrumbs : normalizeBreadcrumb(heading)}
                />
              </Col>
            </ErrorBoundary>
          </Row>
          {isNonEndemicBannerEnabled && isDIYPage
            && (
              <ErrorBoundary name="non-endemic-banner">
                <div className="grid padding-top-15">
                  <SponsoredNonEndemic experienceSlug="content" />
                </div>
              </ErrorBoundary>
            )}
          <Row>
            <ErrorBoundary name="etch-widget">
              <Col flatten>
                <Heading title={heading} level="1" />
                <RecommendationsProvider resx={recxSchema} ready={recommendationsState.ready}>
                  <Row>
                    <Col nopadding className="experience--white-bg">
                      <EtchWidgets contentName={name} previewName={contentName} showDpdPod />
                    </Col>
                    {recommendationsState.schema.con1_home_rr && (
                      <Row>
                        <ErrorBoundary name="con1_home_rr">
                          <Col nopadding>
                            <RecommendationsPlaceholder scheme="con1_home_rr" />
                          </Col>
                        </ErrorBoundary>
                      </Row>
                    )}
                    {recommendationsState.schema.hp_sponsored && (
                      <Row>
                        <ErrorBoundary name="hp_sponsored">
                          <Col nopadding>
                            <RecommendationsPlaceholder scheme="hp_sponsored" />
                          </Col>
                        </ErrorBoundary>
                      </Row>
                    )}
                    {recommendationsState.schema.rv_homepage_rr && (
                      <Row>
                        <ErrorBoundary name="rv_homepage_rr">
                          <Col nopadding>
                            <RecommendationsPlaceholder scheme="rv_homepage_rr" />
                          </Col>
                        </ErrorBoundary>
                      </Row>
                    )}
                  </Row>
                </RecommendationsProvider>
              </Col>
            </ErrorBoundary>
          </Row>
        </Col>
      </Row>
      <Hydrator
        id="content-emt-section"
        className="grid"
        waitFor={[EmtLinksLoad]}
        scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
        preserveCtxVal="clientStore"
      >
        <Row className="isBound">
          <ErrorBoundary name="emt-content" className="isBound u--paddingTop u--paddingBottom">
            <EmtLinks path={pathname} />
          </ErrorBoundary>
        </Row>
      </Hydrator>
    </Row>
  );
};

Content.displayName = 'ContentPage';

Content.propTypes = {};

Content.dataModel = extend(
  {
    content: params({
      name: string().isRequired(),
      contentName: string(),
      channel: customType('Channel').enum(['DESKTOP', 'MOBILE', 'TABLET'], 'DESKTOP'),
    }).shape({
      name: string(),
      breadcrumbs: shape({
        dimensionName: string(),
        url: string(),
        label: string(),
      }),
      metadata: shape({
        canonicalURL: string(),
        description: string(),
        facebookAdmins: string(),
        heading: string(),
        siteName: string(),
        title: string(),
        type: string(),
      }),
      customerType: string(),
    }),
  },
  RecommendationsPlaceholder,
  EtchWidgets,
  Breadcrumbs,
  EmtLinksModel
);
