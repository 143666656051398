import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { params, shape, string, useDataModel } from '@thd-nucleus/data-sources';
import { useHelmet, RedirectTag } from '@thd-nucleus/thd-helmet';
import { RedirectContext } from './RedirectContext';
import { isNotFound } from './rules/content-redirect-rules';
import { handleErrors, ERROR_HANDLING_EXPERIENCE } from '../utils/redirector-utils';

const contentHandler = ({ data, error: err, props, queries }) => {
  let error = err;

  if (queries && queries.length && !err) {
    const contentQuery = queries.find((query) => query.operationName === 'content');
    if (contentQuery && contentQuery.error) {
      error = contentQuery.error;
    }
  }

  const errors = handleErrors(error, ERROR_HANDLING_EXPERIENCE.GENERIC);
  if (errors) {
    return new RedirectTag(errors, props.bypass);
  }

  const notFound = isNotFound(data);
  if (notFound) {
    return new RedirectTag(notFound, props.bypass);
  }

  return null;
};

export const ContentRedirector = ({ name }) => {
  const { bypass } = useContext(RedirectContext);

  const { data, error } = useDataModel('content', { variables: { name } });

  useHelmet('contentRedirector', { data, error, props: { bypass } }, contentHandler, []);

  return null;
};

ContentRedirector.displayName = 'ContentRedirector';

ContentRedirector.propTypes = {
  name: PropTypes.string,
};

ContentRedirector.defaultProps = {
  name: PropTypes.string,
};

ContentRedirector.dataModel = {
  content: params({ name: string().isRequired() }).shape({
    name: string(),
    templates: string()
  })
};
